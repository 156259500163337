import { TileLayer, _WMSLayer as WMSLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";
import LayersIcon from "@mui/icons-material/Layers";
import { Button, Menu, Tooltip } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import useStyles from "../../theme/styles";
import "./style.scss";



const BasemapWidget = (props) => {
  const classes = useStyles();
  const {
    layersArray,
    setLayersArray,
    rightCorner
  } = props

  const [activeLayer, setActiveLayer] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const availableLayers = {
    pnoa_layer: new WMSLayer({
      id: "pnoa_layer",
      data: "https://www.ign.es/wms-inspire/pnoa-ma",
      layers: ["OI.OrthoimageCoverage"],
      serviceType: "wms",
    }),
    bing_layer: new TileLayer({
      id: "bing_layer",
      data: "https://atlas.microsoft.com/map/tile?api-version=2.0&tilesetId=microsoft.imagery&zoom={z}&x={x}&y={y}&subscription-key=2jBK0pEwDDoHxvpXOXwvDiEcnsu0X2LgBSA424KjeGLsJxbR1vbIJQQJ99AFAC5RqLJWu3RuAAAgAZMPAkdU",
      tileSize: 256,
      minZoom: 0,
      maxZoom: 22,
      attribution: "© Microsoft",
      renderSubLayers: props => {
        const { boundingBox } = props.tile;
        return new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]]
        });
      },
    }),
    esri_layer: new TileLayer({
      id: "esri_layer",
      data: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
      tileSize: 256,
      minZoom: 0,
      maxZoom: 22,
      attribution: "© ArcGIS",
      renderSubLayers: props => {
        const { boundingBox } = props.tile;
        return new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]]
        });
      },
    }),
    google_layer: new TileLayer({
      id: "google_layer",
      data: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&s=Ga",
      tileSize: 256,
      minZoom: 0,
      maxZoom: 22,
      attribution: "© Google",
      renderSubLayers: props => {
        const { boundingBox } = props.tile;
        return new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]]
        });
      },
    })
  }

  useEffect(() => {
    const filteredLayerArray = layersArray.filter((layer) => {
      return layer.id.search("_layer") === -1
    })
    if (availableLayers[activeLayer])
      setLayersArray([availableLayers[activeLayer], ...filteredLayerArray])
    else
      setLayersArray(filteredLayerArray)
  }, [activeLayer]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleChangeLayer = (event) => {
    setActiveLayer(event.target.value)
  }

  return (
    <div>
      <div className={classes.divBtn}>
        <Tooltip title="Basemap">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className={`btn-map-first ${classes.btn} ${open ? classes.btnOpen : classes.btnNoOpen}`}
            variant="contained"
            startIcon={<LayersIcon className={classes.btn_search} />}
          ></Button>
        </Tooltip>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ marginTop: "0.5px" }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: rightCorner ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: rightCorner ? 'right' : 'left',
        }}

      >
        <FormControl sx={{ paddingLeft: "10px" }}>
          <RadioGroup
            value={activeLayer || 'mapbox_layer'}
            onChange={handleChangeLayer}
          >
            <FormControlLabel
              value="bing_layer"
              control={<Radio size="small" sx={{ color: '#7024D1', '&.Mui-checked': { color: '#7024D1' } }} />}
              label={<Typography sx={{ fontSize: 'small' }}>Bing (World)</Typography>} />
            <FormControlLabel
              value="esri_layer"
              control={<Radio size="small" sx={{ color: '#7024D1', '&.Mui-checked': { color: '#7024D1' } }} />}
              label={<Typography sx={{ fontSize: 'small' }}>Esri (World)</Typography>} />
            <FormControlLabel
              value="google_layer"
              control={<Radio size="small" sx={{ color: '#7024D1', '&.Mui-checked': { color: '#7024D1' } }} />}
              label={<Typography sx={{ fontSize: 'small' }}>Google (World)</Typography>} />
            <FormControlLabel
              value="mapbox_layer"
              control={<Radio size="small" sx={{ color: '#7024D1', '&.Mui-checked': { color: '#7024D1' } }} />}
              label={<Typography sx={{ fontSize: 'small' }}>Mapbox (World)</Typography>} />
            <FormControlLabel
              value="pnoa_layer"
              control={<Radio size="small" sx={{ color: '#7024D1', '&.Mui-checked': { color: '#7024D1' } }} />}
              label={<Typography sx={{ fontSize: 'small' }}>PNOA (Spain)</Typography>} />
          </RadioGroup>
        </FormControl>

      </Menu>
    </div>
  );
};

BasemapWidget.propTypes = {
  mapObject: PropTypes.any,
  changeViewport: PropTypes.func,
};

export default BasemapWidget;
