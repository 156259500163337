import React, { useState, useContext } from "react";
import { Button, IconButton, FormControl, InputAdornment, TextField, InputLabel, Link, Typography, FilledInput } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { userLogin } from "../../utils/requests";
import { User } from "../../models/user";
import useStyles from "../../theme/styles";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Imageryst11, Imageryst7 } from "../../icons/logo";

interface State {
  password: string;
  showPassword: boolean;
  email: string;
}

function Login(props: any) {
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation();

  const initialUser: User = { email: "", password: "" };

  const [user, setUser] = useState(initialUser);
  const [errorLogin, setErrorLogin] = useState(false);
  const [loginWaiting, setLoginWaiting] = useState(false);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorLogin(false);
    setValues({ ...values, [prop]: event.target.value });
    setUser((prevState) => ({
      ...prevState,
      [prop]: event.target.value,
    }));
  };

  const login = async (e: any) => {
    try {
      if (e) {
        e.preventDefault();
        setLoginWaiting(true);
        const response = await userLogin(user);
        localStorage.setItem("token", response.data.access_token);
        if (response.status === 200 && response.data.access_token) {
          const access_lifespan = response.data.access_lifespan;
          const access_token = response.data.access_token;

          const id_user = response.data.data.id_user;

          localStorage.setItem("id_user", id_user);

          localStorage.setItem("token_access_imageryst", access_token);

          history.push("/projects");
          setLoginWaiting(false);
        } else {
          setErrorLogin(true);
          setLoginWaiting(false);
        }
      }
    } catch (e) {
      setErrorLogin(true);
      setLoginWaiting(false);
    }
  };

  const [values, setValues] = useState<State>({
    password: "",
    showPassword: false,
    email: "",
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className={`container_logo ${loginWaiting ? "cursorProgress" : ""}`}>
      <div className="container_img">
        <div className="img_login_gradient"></div>
        <div>
          <Imageryst11 sx={{ width: "494px", height: "90px" }} />
          <div className="title_text">Analyze and monitor your assets from above</div>
        </div>
      </div>
      <div className="container_form">
        <form onSubmit={login}>
          <Imageryst7 sx={{height: "83px", width: "89px", "margin-bottom": "82px"}} />
          <TextField
            label={t("login.email")}
            variant="filled"
            onChange={handleChange("email")}
            className={`input ${classes.colorTextFieldAlways}`}
            error={errorLogin}
            id="standard-required"
            style={{ marginBottom: 30 }}
          />
          <FormControl variant="filled"
            className="input">
            <InputLabel htmlFor="standard-adornment-password" className={classes.labelColor}>{t("login.password")}</InputLabel>
            <FilledInput
              className={`input ${classes.colorPassword}`}
              error={errorLogin}
              id="standard-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff style={{ color: "#7024D1" }} /> : <Visibility style={{ color: "#7024D1" }} />}
                  </IconButton>
                </InputAdornment>
              }
            /></FormControl>
          {errorLogin && (
            <Typography variant="caption" display="block" gutterBottom color="red" className="errorLogin">
              {t('login.loginError')}{" "}
              <Link href="mailto:contact@imageryst.com">contact@imageryst.com</Link>
            </Typography>
          )}
          <Button variant="contained" className="btnLogin" type="submit">
            {t('common.confirmButton')}
          </Button>
          <Typography variant="caption" display="block" gutterBottom>
            {t('login.createAccount')} <Link href="mailto:contact@imageryst.com">{t('login.contactUs')}</Link>
          </Typography>
        </form>
      </div>
    </div>
  );
}

export default Login;
