import { Modal, Box, Typography, TextField } from "@mui/material";
import ButtonCustom from "../../ui/button";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "../../../theme/styles";
import "./style.scss";
import { useTranslation } from "react-i18next";

interface ModalCustomProps {
  open: boolean;
  setOpen: any;
  content: React.ReactNode;
  accept?: any;
  hasButton?: boolean;
  labelOK?: string;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ModalCustom({ open, setOpen, content, accept, hasButton = true, labelOK = "common.confirmButton" }: ModalCustomProps) {
  const classes = useStyles();

  const {t} = useTranslation()

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!hasButton && <CloseIcon onClick={handleClose} className={`${classes.iconClose}`} />}
        {content}
        {hasButton && (
          <>
            <ButtonCustom label={t(labelOK)} className="buttonStyleColor" variant="contained" handleClick={accept} />
            <ButtonCustom
              label={t("common.cancelButton")}
              className="buttonStyleColoOutline"
              variant="contained"
              handleClick={handleClose}
            />
          </>
        )}
      </Box>
    </Modal>
  );
}

export default ModalCustom;
