import { useState } from "react";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, CircularProgress, Menu, MenuItem, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { downloadDataset } from "../../../utils/requests";
import fileDownload from "js-file-download";
import Loading from "../../loading";
import "./style.scss"


function DownloadDataset(props: any) {
    const { t } = useTranslation()
    const {
        id_dataset,
        id_project,
        project_name,
        name_asset,
        name_attribute,
        index,
        setSnackbarProps,
        setOpenSnackbar
    } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDonwload, setOpenDonwload] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const FILE_OPTIONS = {
        "GeoJSON": { text: ".geojson", extension: ".geojson" },
        "KML": { text: ".kml", extension: ".kml" },
        "CSV": { text: ".csv", extension: ".csv" },
        "Shapefile": { text: ".shp", extension: ".zip" },
        "DXF": { text: ".dxf", extension: ".dxf" },
    }

    const openMenu = (event: any) => {
        event?.stopPropagation();
        setOpenDonwload(!openDonwload)
        setAnchorEl(event?.currentTarget);
    };

    const handleDownloadDataset = async (event: any, id_project: any, id_dataset: any, project_name: any, name_asset: any, name_attribute: any, extension: any) => {
        event.stopPropagation();
        const typeExtension = event.currentTarget.innerText.toLowerCase();
        const filename =
            project_name.replaceAll(" ", "_").toLowerCase() +
            name_attribute.replaceAll(" ", "_").toLowerCase() +
            name_asset.replaceAll(" ", "_").toLowerCase();

        setIsDownloading(true)
        openMenu(null);
        await downloadDataset(id_project, id_dataset, typeExtension)
            .then(async function (response) {
                if (response.status === 200) {
                    fileDownload(response.data, `${filename}${extension}`)
                }
            })
            .catch(function (err) {
                setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
                setOpenSnackbar(true)
            }).finally(() => {
                setIsDownloading(false)
            });
    };

    const renderFileOptions = () => {
        return Object.entries(FILE_OPTIONS).map(([fileType, fileExtension]) => {
            return (
                <Tooltip title={`${t('common.download')} ${fileExtension.text}`}>
                    <MenuItem
                        key={`${index}_geojson`}
                        data-id={id_dataset}
                        onClick={(
                            e: any
                        ) =>
                            handleDownloadDataset(
                                e,
                                id_project,
                                id_dataset,
                                project_name,
                                name_asset,
                                name_attribute,
                                fileExtension.extension
                            )
                        }>{fileType}</MenuItem>
                </Tooltip>)
        })
    }

    return (
        <>
            {
                !isDownloading ?
                    <span onClick={(e: any) => openMenu(e)}
                        data-id={id_dataset}>
                        <FileDownloadOutlinedIcon titleAccess={t('common.download')}
                            sx={{
                                pointerEvents: "auto",
                            }} />
                        <Menu
                            anchorEl={anchorEl}
                            open={openDonwload}
                            onClose={(e: any) => openMenu(e)}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            disableScrollLock={true}
                            key={`${index}_menu`}
                        >
                            {renderFileOptions()}
                        </Menu>
                    </span> :
                    <Box sx={{ marginLeft: 1 }}>
                        <Loading size={'fit-content'} />
                    </Box>
            }
        </>
    )
}

export default DownloadDataset;