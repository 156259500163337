import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Title, Legend } from 'chart.js'

Chart.register([ArcElement, Tooltip, Title, Legend]);


const COLOR_SCALE_RADIATION = [
  "rgb(59, 27, 0)",
  "rgb(93, 42, 0)",
  "rgb(171, 86, 3)",
  "rgb(198, 105, 5)",
  "rgb(237, 165, 33)",
  "rgb(250, 198, 53)",
  "rgb(255, 230, 70)",
];

const COLOR_SCALE_ASPECT = [
  "rgb(141, 90, 153)",
  "rgb(250, 232, 35)",
  "rgb(251, 22, 12)",
  "rgb(60, 234, 17)",
  "rgb(141, 90, 153)",
];

const COLOR_SCALE_SLOPE = [
  "rgb(26, 150, 65)",
  "rgb(166, 217, 106)",
  "rgb(230, 255, 192)",
  "rgb(253, 174, 97)",
  "rgb(215, 25, 28)",
];

const COLOR_SCALE_NDVI = [
  "rgb(251, 22, 12)",
  "rgb(239, 69, 17)",
  "rgb(239, 171, 25)",
  "rgb(26, 150, 65)",
  "rgb(21, 225, 72)",
  "rgb(124, 237, 96)",
];

const COLOR_SCALE_ALTITUDE = [
  "rgb(0, 102, 51)",
  "rgb(0, 153, 51)",
  "rgb(0, 204, 102)",
  "rgb(153, 255, 102)",
  "rgb(255, 255, 102)",
  "rgb(255, 204, 102)",
  "rgb(255, 153, 51)",
  "rgb(204, 102, 0)",
  "rgb(153, 102, 51)",
  "rgb(102, 51, 0)",
];

const COLOR_SCALE_VEGETATION_HEALTH = [
  "rgb(215, 25, 28)",
  "rgb(240, 124, 74)",
  "rgb(254, 201, 129)",
  "rgb(196, 230, 135)",
  "rgb(119, 195, 92)",
  "rgb(91, 174, 61)",
  "rgb(26, 150, 65)",
];

const COLOR_SCALE_HUMIDITY = [
  "rgb(35,145,36)",
  "rgb(154,204,151)",
  "rgb(210, 252, 205)",
  "rgb(213, 242, 255)",
  "rgb(32, 144, 251)",
  "rgb(11, 51, 236)",
  "rgb(5, 25, 135)",
];

const COLOR_SCALE_WATER_BODIES = [
  "rgb(42, 185, 238)",
  "rgb(66, 73, 177)",
  "rgb(48, 18, 59)",
];

const COLOR_SCALE_NIGHT_TIME_LIGHTS = [
  "rgb(0, 0, 0)",
  "rgb(36, 31, 0)",
  "rgb(73, 62, 0)",
  "rgb(109, 93, 0)",
  "rgb(146, 124, 0)",
  "rgb(182, 155, 0)",
  "rgb(219, 186, 0)",
  "rgb(255, 213, 0)",
];

const COLOR_SCALE_EARTHWORK_MONITORING = [
  "rgb(30, 115, 2)",
  "rgb(115, 59, 2)",
  "rgb(100, 100, 100)",
];

const COLOR_SCALES: any = {
  "Altitude": COLOR_SCALE_ALTITUDE,
  "Radiation": COLOR_SCALE_RADIATION,
  "Aspect": COLOR_SCALE_ASPECT,
  "Slope": COLOR_SCALE_SLOPE,
  "Slope (Pitch)": COLOR_SCALE_SLOPE,
  "NDVI": COLOR_SCALE_NDVI,
  "Vegetation health": COLOR_SCALE_VEGETATION_HEALTH,
  "Humidity": COLOR_SCALE_HUMIDITY,
  "Water bodies": COLOR_SCALE_WATER_BODIES,
  "Night time lights": COLOR_SCALE_NIGHT_TIME_LIGHTS,
  "Height": COLOR_SCALE_ALTITUDE,
  "Vegetation height": COLOR_SCALE_ALTITUDE,
  "Earthworks Monitoring": COLOR_SCALE_EARTHWORK_MONITORING
}


function PieChart(props: any) {
  const { type, title, kpisChartInfo } = props;

  const getDataInfo = () => {
    return {
      labels: kpisChartInfo.map((value: any) => value[0]),
      datasets: [{
        label: "Total",
        data: kpisChartInfo.map((value: any) => value[1]),
        backgroundColor: COLOR_SCALES[type],
        hoverOffset: 4
      }]
    }
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          filter: function (legendItem: any, data: any) {
            const dataset = data.datasets[0];
            return dataset.data[legendItem.index] !== 0;
          },
          padding: 10,
          font: {
            size: 14,
            family: "'Arial', sans-serif"
          }
        }
      }
    }
  };

  return (
    <div className="chart-container"> {/* Asegúrate de agregar esta clase */}
      {type && title && kpisChartInfo ?
        <Pie data={getDataInfo()} options={options} />
        : null
      }
    </div>
  );
}

export default PieChart;