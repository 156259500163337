
import { styled } from "@mui/material/styles";

import ButtonCustom from "../../../components/ui/button";
import useStyles from "../../../theme/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { checkAoi } from "../../../utils/requests";

function Step2(props: any) {

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const { t } = useTranslation()

  const {
    activeStep,
    stepIndex,
    attributeListMaestros,
    objective,
    setObjective,
    btnDrawActive,
    activeDraw,
    btnUploadActive,
    handleUploadFile,
    geocoderContainerRef,
    features,
    isNextStepBlocked,
    handleStep,
    inputImages,
    setInputImages,
    setSnackbarProps,
    setOpenSnackbar
  } = props

  const classes = useStyles()

  const filterInputImages = (availableBaseImages: any) => {
    const newInputImages = inputImages.map((_o: any, index: number) => {
      const attr = attributeListMaestros.find((att: any) => att.id_algorithm_attribute === objective[index].attribute);
      if (attr && attr.base_image) {
        const baseImageDict = JSON.parse(attr.base_image);
        const baseImageKeys = Object.keys(baseImageDict).reduce((acc: any, key) => {
          const children = baseImageDict[key];
          const trueChildren = Object.entries(children).reduce((childAcc: any, [childKey, childValue]) => {
            if (childValue === "True" || childKey === "default") {
              childAcc.push(childKey)
            }
            return childAcc;
          }, []);

          if (trueChildren.length > 0) {
            acc[key] = trueChildren;
          }
          return acc;
        }, {});

        const filteredInputImages = Object.entries(baseImageKeys).reduce((objectiveAcc: any, [key, value]: any) => {
          if (key === "Digital Terrain Models") {
            const filtered = value.reduce((acc: any, basemap: any) => {
              if (availableBaseImages.includes(basemap.toLowerCase())) {
                acc.push(basemap)
              }
              return acc
            }, [])
            if (filtered.length > 0)
              objectiveAcc = { ...objectiveAcc, [key]: filtered }
          }
          else {
            objectiveAcc = { ...objectiveAcc, [key]: value }
          }
          return objectiveAcc
        }, {})
        if (Object.keys(filteredInputImages).length > 0)
          return filteredInputImages
      }
    })
    const filteredNewInputImages = newInputImages.filter((newInputImage: any) => newInputImage !== undefined)
    return filteredNewInputImages.length === objective.length ? filteredNewInputImages : null
  }

  const hasLidar = () => {
    const hasLidar = objective.reduce((acc: boolean, ob: any) => {
      return acc || ob.selectImageType === "Digital Terrain Models"
    }, false)
    return hasLidar
  }

  const handleNextStep = async (nextStep: number) => {
    if (hasLidar())
      await checkAoi(features).then(
        function (response) {
          if (response.status === 200) {
            const availableBaseImages = response.data.message
            const newInputImages = filterInputImages(availableBaseImages)
            if (newInputImages) {
              setInputImages(newInputImages)
              const newObjective = objective.map((o: any, idx: number) => {
                const images = newInputImages[idx]
                if (images[o.selectImageType]) {
                  if (images[o.selectImageType].includes(o.selectSubImageType)) {
                    return o
                  }
                  return { ...o, selectSubImageType: "" }
                }
                return { ...o, selectImageType: "", selectSubImageType: "" }
              })
              setObjective(newObjective);
              handleStep(nextStep)();
            }
            else {
              setSnackbarProps({ message: "No data for this aoi.", severity: 'error' })
              setOpenSnackbar(true)
            }
          }
        }
      ).catch(function (err) {
        setSnackbarProps({ message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"), severity: 'error' })
        setOpenSnackbar(true)
      });
    else {
      const newInputImages = filterInputImages([])
      setInputImages(newInputImages)
      handleStep(nextStep)();
    }

  }


  return (
    <div className={activeStep === stepIndex ? classes.activeStep : classes.disabledStep}>
      <div className="containerStep2">
        <div>
          <ButtonCustom
            label={t('common.drawButton')}
            className={`${btnDrawActive ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
            variant="contained"
            handleClick={activeDraw}
            disabled={activeStep !== stepIndex}
            tooltip={t('newProject.drawTooltip')}
          />
          <ButtonCustom
            label={t('common.uploadButton')}
            className={`${btnUploadActive ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
            variant="contained"
            //handleClick={handleOpenModalArea}
            disabled={activeStep !== stepIndex}
            tooltip={t('newProject.uploadTooltip')}
            content={
              <VisuallyHiddenInput type="file" accept=".kml, .geojson, .shp, .kmz, .dxf" onChange={handleUploadFile} />
            }
          />

        </div>
        {activeStep !== stepIndex && <div className="divDisable"></div>}
        <div ref={geocoderContainerRef}></div>
      </div>
      <Box sx={{ mb: 2 }}>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ""}`}>
          <ButtonCustom
            label={t('common.backButton')}
            startIcon={<ArrowBackIcon />}
            className="backButton"
            handleClick={handleStep(0)}
            tooltip={t('common.backTooltip')}
          />
          <ButtonCustom
            label={t('common.continueButton')}
            className={isNextStepBlocked ? "buttonStyleColorDisable" : "buttonStyleColor"}
            variant="contained"
            handleClick={() => handleNextStep(2)}
            tooltip={t('common.continueTooltip', { extraText: t('common.continueImage') })}
            disabled={isNextStepBlocked}
          />
        </div>
      </Box>
    </div>
  );
}

export default Step2
