import { Avatar, Box, Button, Card, CardContent, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Modal, Paper, Radio, RadioGroup, Stack, styled, Tab, Tabs, TextField, Typography } from "@mui/material";
import Header from "../../components/header/header";
import './style.scss'
import Footer from "../../components/footer";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { editUserData, getUserData } from "../../utils/requests";
import { Trans, useTranslation } from "react-i18next";
import CustomSnackbar from "../../components/snackbar";

type UserConsumption = {
    currentMonthUsage: number;
    maxMonthUsage: number;
    monthRemaining: number;
    monthUsePercentage: number;
    currentYearUsage: number;
    maxYearUsage: number;
    yearUsePercentage: number;
    yearRemaining: number;
}

type UserInfo = {
    username: string;
    email: string;
    units: string;
    unitsValue: number;
    consumptions: UserConsumption[]
};

function UserProfile() {

    const { t } = useTranslation()

    const [openLoader, setOpenLoader] = useState(false);
    const [snackbarProps, setSnackbarProps] = useState({ message: '', severity: '' })
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const [showArcgisPassword, setShowArcgisPassword] = useState(false);
    const handleClickShowArcgisPassword = () => setShowArcgisPassword((show) => !show);

    const [tabValue, setTabValue] = useState(0);
    const [userInfo, setUserInfo] = useState<UserInfo>({
        username: '--',
        email: '--',
        units: '--',
        unitsValue: 0,
        consumptions: [{
            currentMonthUsage: 0,
            maxMonthUsage: 0,
            monthRemaining: 0,
            monthUsePercentage: 0,
            currentYearUsage: 0,
            maxYearUsage: 0,
            yearRemaining: 0,
            yearUsePercentage: 0,
        }]
    })

    const [unitsValue, setUnitsValue] = useState(0)

    const handleUnitsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnitsValue(Number(event.target.value))
    }


    const [openArcGisModal, setOpenArcGisModal] = useState(false);
    const handleCloseArcGisModal = () => {
        setOpenArcGisModal(false)
    }
    const handleOpenArcGisModal = () => {
        setOpenArcGisModal(true)
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleSumbmitArcgis = async (event: React.FormEvent) => {
        event.preventDefault();
        console.log("submit")
    }

    const handleCloseModal = () => setOpenLoader(false);

    useEffect(() => {
        setOpenLoader(true);
        getUserInfo();
    }, []);

    const getUserInfo = async () => {
        await getUserData()
            .then(function (response) {
                if (response?.status === 200) {
                    setOpenLoader(false);
                    setUserInfo((previous) => {
                        return {
                            ...previous,
                            ...response.data.message,
                        }
                    })
                    setUnitsValue((prev) => response.data.message.unitsValue ? response.data.message.unitsValue : prev)
                } else {
                    setOpenLoader(false);
                }
            })
            .catch(function (err) {
                // some error here
                setOpenLoader(false);
                if (!err.response || err.response?.status !== 404) {
                    setSnackbarProps({ message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"), severity: 'error' })
                    setOpenSnackbar(true)
                }
            });
    }

    const handleClickSaveChanges = () => {
        setOpenLoader(true);
        saveChanges();
    }

    const saveChanges = async () => {
        const newUserInfo = {
            unitsValue: unitsValue
        }
        await editUserData(newUserInfo).then(function (response) {
            if (response?.status === 200) {
                setOpenLoader(false);
                setUserInfo((previous) => {
                    return {
                        ...previous,
                        ...response.data.message,
                    }
                })
                setUnitsValue((prev) => response.data.message.unitsValue ? response.data.message.unitsValue : prev)
            } else {
                setOpenLoader(false);
            }
        })
            .catch(function (err) {
                // some error here
                setOpenLoader(false);
                if (!err.response || err.response?.status !== 404) {
                    setSnackbarProps({ message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"), severity: 'error' })
                    setOpenSnackbar(true)
                }
            });
    }

    const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: value !== undefined && value > 100 ? '#B00020' : '#f8f8fd',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: value !== undefined && value > 100 ? '#B00020' : '#7242d4',
        },
    }));

    return (
        <div>
            <Modal
                open={openLoader}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="containerLoader">
                    <CircularProgress
                        sx={{
                            color: "#7024D1",
                        }}
                        size={70}
                    />
                </div>
            </Modal>
            <Header />
            <Box className="container_user_profile" >
                <Box className="container_user_profile_photo">
                    <Stack spacing={5} sx={{ paddingTop: '50px', }}>
                        <Box>
                            <Avatar sx={{
                                width: 200,
                                height: 200,
                                backgroundImage: 'radial-gradient(circle, #7242d4 0%, #0c0227 80%)',
                            }}>{userInfo.username ? userInfo.username[0].toUpperCase() : '--'}</Avatar>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Typography>
                                {userInfo.username}
                            </Typography>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Typography onClick={handleOpenArcGisModal} sx={{ cursor: 'pointer', color: '#000', textDecoration: 'underline' }}>
                                {t('userProfile.arcgisConnect')}
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box className="container_user_profile_info">
                    <Grid container rowSpacing={10} sx={{ paddingTop: '50px', paddingLeft: '32px', paddingBottom: '40px' }}>
                        <Grid item xs={12}>
                            <Stack spacing={4}>
                                <Box>
                                    <Typography variant="h5">
                                        {t('userProfile.info')}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: 300 }}>
                                    <Typography variant="h6">
                                        {t('userProfile.user')}
                                    </Typography>
                                    <TextField fullWidth variant="outlined" disabled value={userInfo.username} />
                                </Box>
                                <Box sx={{ width: 300 }}>
                                    <Typography variant="h6">
                                        {t('userProfile.measurementUnit')}
                                    </Typography>
                                    <FormControl>
                                        <RadioGroup
                                            value={unitsValue}
                                            onChange={handleUnitsChange}
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value={1} control={<Radio className="radio-dashboard" />} label="km²" />
                                            <FormControlLabel value={3} control={<Radio className="radio-dashboard" />} label="yd²" />
                                            <FormControlLabel value={5} control={<Radio className="radio-dashboard" />} label="ha" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>

                                <Box sx={{ width: 300 }}>
                                    <Button
                                        variant="contained"
                                        className={`buttonImageryst ${!(unitsValue === userInfo.unitsValue) ? 'buttonStyleColor' : 'buttonStyleColorDisable'}`}
                                        disabled={unitsValue === userInfo.unitsValue}
                                        onClick={handleClickSaveChanges}
                                    >
                                        {t('common.saveButton')}
                                    </Button>

                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Box >
                                <Typography variant="h5">
                                    {t('userProfile.plan')}
                                </Typography>
                            </Box>

                            <Box>
                                <Box className="user-panel-box" sx={{ paddingTop: 4 }}>
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleChangeTab}
                                        textColor="secondary"
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "transparent",
                                            },
                                        }}
                                    >
                                        <Tab className="user-tab" label={t('userProfile.monthTab')} value={0} />
                                        <Tab className="user-tab" label={t('userProfile.yearTab')} value={1} />
                                    </Tabs>
                                </Box>
                                <Box className="user-panel-box">
                                    {tabValue === 0 && (
                                        <Card sx={{ border: '1px solid lightgrey', padding: 0 }}>
                                            <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
                                                <Grid container>
                                                    <Grid item xs={12} sx={{ padding: 2, }} >
                                                        <Typography variant="h5" sx={{ color: userInfo.consumptions[0]?.monthUsePercentage < 100 ? '#7242d4' : '#B00020' }}>
                                                            <Trans
                                                                i18nKey={'userProfile.used'}
                                                                components={{ strong: <strong /> }}
                                                                values={{ value: `${userInfo.consumptions[0].monthUsePercentage} %` }}
                                                            />
                                                        </Typography>
                                                        <BorderLinearProgress variant="determinate" value={userInfo.consumptions[0].monthUsePercentage} />
                                                    </Grid>
                                                    <Grid item container xs={12} sx={{ paddingLeft: 2, paddingRight: 2, borderTop: 1, borderColor: "lightgrey" }}>
                                                        <Grid item>
                                                            <Typography variant="body1" sx={{ paddingTop: 2, paddingBottom: 2, paddingRight: 2, borderRight: 1, borderColor: "lightgrey" }}>
                                                                <Trans
                                                                    i18nKey={'userProfile.used'}
                                                                    components={{ strong: <strong /> }}
                                                                    values={{ value: `${userInfo.consumptions[0].currentMonthUsage} ${userInfo.units}` }}
                                                                />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1" sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2, }}>
                                                                <Trans
                                                                    i18nKey={'userProfile.remaining'}
                                                                    components={{ strong: <strong /> }}
                                                                    values={{ value: `${userInfo.consumptions[0].monthRemaining} ${userInfo.units}` }}
                                                                />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    )}

                                    {tabValue === 1 && (
                                        <Card sx={{ border: '1px solid lightgrey', padding: 0 }}>
                                            <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
                                                <Grid container>
                                                    <Grid item xs={12} sx={{ padding: 2, }} >
                                                        <Typography variant="h5" sx={{ color: userInfo.consumptions[0].yearUsePercentage < 100 ? '#7242d4' : '#B00020' }}>
                                                            <Trans
                                                                i18nKey={'userProfile.used'}
                                                                components={{ strong: <strong /> }}
                                                                values={{ value: `${userInfo.consumptions[0].yearUsePercentage} %` }}
                                                            />
                                                        </Typography>
                                                        <BorderLinearProgress variant="determinate" value={userInfo.consumptions[0].yearUsePercentage} />
                                                    </Grid>
                                                    <Grid item container xs={12} sx={{ paddingLeft: 2, paddingRight: 2, borderTop: 1, borderColor: "lightgrey" }}>
                                                        <Grid item>
                                                            <Typography variant="body1" sx={{ paddingTop: 2, paddingBottom: 2, paddingRight: 2, borderRight: 1, borderColor: "lightgrey" }}>
                                                                <Trans
                                                                    i18nKey={'userProfile.used'}
                                                                    components={{ strong: <strong /> }}
                                                                    values={{ value: `${userInfo.consumptions[0].currentYearUsage} ${userInfo.units}` }}
                                                                />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1" sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                                                                <Trans
                                                                    i18nKey={'userProfile.remaining'}
                                                                    components={{ strong: <strong /> }}
                                                                    values={{ value: `${userInfo.consumptions[0].yearRemaining} ${userInfo.units}` }}
                                                                />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    )}
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Footer />
            <Modal
                open={openArcGisModal}
                onClose={handleCloseArcGisModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        height: '100vh',
                    }}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            sx={{
                                bgcolor: "background.paper",
                                borderRadius: 2,
                                boxShadow: 24,
                                p: 4,
                            }}
                            component={"form"}
                            onSubmit={handleSumbmitArcgis}
                        >
                            <Stack spacing={4}>
                                <Typography>
                                    {t('userProfile.arcgisLoginTitle')}
                                </Typography>
                                <Box>
                                    <Typography>
                                        {t('userProfile.user')}
                                    </Typography>
                                    <TextField fullWidth required variant="outlined" placeholder={t('userProfile.userPlaceholder')}></TextField>
                                </Box>
                                <Box>
                                    <Typography>
                                        {t('login.password')}
                                    </Typography>
                                    <TextField
                                        type={showArcgisPassword ? 'text' : 'password'}
                                        fullWidth
                                        required
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowArcgisPassword}
                                                        edge="end"
                                                    >
                                                        {showArcgisPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>

                                <Box>
                                    <Box>
                                        <Typography>
                                            URL
                                        </Typography>
                                        <TextField fullWidth required variant="outlined" placeholder={t('userProfile.arcgisUrlPlaceholder')}></TextField>
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Box sx={{ marginRight: 2 }}>
                                        <Button
                                            variant="contained"
                                            className={`buttonImageryst buttonStyleColoOutline`}
                                            onClick={handleCloseArcGisModal}
                                        >
                                            {t('common.cancelButton')}
                                        </Button>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        className={`buttonImageryst buttonStyleColor`}
                                        type='submit'
                                    >
                                        {t('common.confirmButton')}
                                    </Button>

                                </Box>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
            <CustomSnackbar
                snackbarProps={snackbarProps}
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar} />
        </div>
    )
}

export default UserProfile;