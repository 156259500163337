import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Box,
    TextField,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Tooltip, IconButton } from "@mui/material";
import ButtonCustom from "../../../components/ui/button";
import useStyles from "../../../theme/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function Step5(props: any) {
    const {
        className,
        activeStep,
        stepIndex,
        projectDescription,
        setProjectDescription,
        handleStep,
    } = props

    const classes = useStyles();

    const { t } = useTranslation()

    return (
        <div className={className}>
            <TextField
                label="Descripción"
                multiline
                disabled={activeStep!==stepIndex}
                rows={4}
                variant="outlined"
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                fullWidth
            />
            <Box>
                <div className={`${stepIndex !== activeStep ? classes.hideBtn : ""}`}>
                    <ButtonCustom
                        label={t('common.backButton')}
                        startIcon={<ArrowBackIcon />}
                        className="backButton"
                        handleClick={handleStep(3)}
                        tooltip={t('common.backTooltip')}
                    />
                </div>
            </Box>
        </div>
    );
}

export default Step5;