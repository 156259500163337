// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: fixed;
  bottom: 0px;
  background-color: white;
  width: 100%;
  text-align: center;
  color: #6d7278;
  z-index: 10;
}
.footer p {
  font-size: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;AACF;AACE;EACE,0BAAA;AACJ","sourcesContent":[".footer {\n  position: fixed;\n  bottom: 0px;\n  background-color: white;\n  width: 100%;\n  text-align: center;\n  color: #6d7278;\n  z-index: 10;\n\n  p {\n    font-size: 10px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
