import { Checkbox } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import "./style.scss";

function CheckboxCustom(props: any) {
  const { checked, handleChange, value = "", disabled = false, style = false, icons = false } = props;

  return (
    <>
      {style ? (
        <Checkbox
          onClick={(e) => handleChange(e)}
          checked={checked}
          value={value}
          disabled={disabled}
          style={style}
          icon={icons ? (checked ? <VisibilityIcon /> : <VisibilityOffIcon style={{ color: 'black' }} />) : <CheckBoxOutlineBlankIcon />}
          checkedIcon={icons ? <VisibilityIcon /> : <CheckBoxIcon />}
        />
      ) : (
        <Checkbox
          onClick={(e) => handleChange(e)}
          checked={checked}
          value={value}
          disabled={disabled}
          icon={icons ? (checked ? <VisibilityIcon /> : <VisibilityOffIcon style={{ color: 'black' }} />) : <CheckBoxOutlineBlankIcon />}
          checkedIcon={icons ? <VisibilityIcon /> : <CheckBoxIcon />}
        />
      )}
    </>
  );
}

export default CheckboxCustom;
