// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_user_profile {
  margin-top: 73px;
  display: flex;
  justify-content: center;
  height: calc(100vh - 93px);
}

.container_user_profile_photo {
  display: flex;
  width: 20%;
  height: 100%;
  justify-content: center;
  background-color: #fff;
}

.container_user_profile_info {
  width: 80%;
  height: 100%;
  background-color: #f8f8fd;
  padding-left: 20px;
  padding-top: 20px;
  overflow-y: auto;
  height: calc(100vh - 110px);
}

.user-panel-box {
  margin-right: 45%;
}
.user-panel-box .user-tab {
  width: 10%;
  color: black !important;
  text-transform: capitalize !important;
}
.user-panel-box .user-tab.Mui-selected {
  border: 1px solid lightgrey !important;
  border-bottom: none !important;
  border-radius: 10px 10px 0 0;
  background-color: white !important;
  color: black !important;
  font-weight: bold;
}

.radio-dashboard.Mui-checked {
  color: #7024d1 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,UAAA;EACA,YAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,UAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,2BAAA;AACF;;AAEA;EACE,iBAAA;AACF;AAAE;EACE,UAAA;EACA,uBAAA;EACA,qCAAA;AAEJ;AADI;EACE,sCAAA;EACA,8BAAA;EACA,4BAAA;EACA,kCAAA;EACA,uBAAA;EACA,iBAAA;AAGN;;AAGE;EACI,yBAAA;AAAN","sourcesContent":[".container_user_profile {\n  margin-top: 73px;\n  display: flex;\n  justify-content: center;\n  height: calc(100vh - 93px);\n}\n\n.container_user_profile_photo {\n  display: flex;\n  width: 20%;\n  height: 100%;\n  justify-content: center;\n  background-color: #fff;\n}\n\n.container_user_profile_info {\n  width: 80%;\n  height: 100%;\n  background-color: #f8f8fd;\n  padding-left: 20px;\n  padding-top: 20px;\n  overflow-y: auto;\n  height: calc(100vh - 110px);\n}\n\n.user-panel-box{\n  margin-right: 45%;\n  .user-tab {\n    width: 10%;\n    color: black !important;\n    text-transform: capitalize !important;\n    &.Mui-selected {\n      border: 1px solid lightgrey !important;\n      border-bottom: none !important;\n      border-radius: 10px 10px 0 0;\n      background-color: white !important;\n      color: black !important;\n      font-weight: bold;\n    }\n  }\n}\n\n.radio-dashboard {\n  &.Mui-checked {\n      color: #7024d1 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
