import { FileCopyOutlined } from "@mui/icons-material";

import {
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "../../theme/styles";
import "./style.scss";
import { PieChart } from "../chart";
import DatasetInfoCard from './datasetInfoCard';
import DatasetKpisInfo from "./datasetKpisInfo";

function MenuMap(props: any) {
  const {
    openPanel,
    listDataset,
    updateDataset,
    handleChangeCheckLayer,
    dataResultsMap,
    handleChangeSelect,
    optionSelectKPI,
    handleChangeTransparency,
    handleChangeTransparencyXyz,
    area,
    setSnackbarProps,
    setOpenSnackbar,
  } = props;


  const { t } = useTranslation()

  const [value, setValue] = useState("0");
  const [xyzLayerActive, setXyzLayerActive] = useState<{ [id: string]: boolean }>({});

  const toggleXyzLayer = (id: string, checked: boolean | undefined = undefined) => {
    setXyzLayerActive((prevState) => {
      return {
        ...prevState,
        [id]: checked === undefined ? !prevState[id] : checked
      };
    });
  };

  const handleChangeCheckDataset = (e: React.ChangeEvent<HTMLInputElement>, id_project: string, id_dataset: string, name_attribute: string, transparency: number, subimage: string) => {
    const checked = e.target.checked
    toggleXyzLayer(id_dataset, checked)
    handleChangeCheckLayer(e, id_project, id_dataset, name_attribute, transparency, subimage)
  }

  const token = localStorage.getItem("token");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleCopyPassword = () => {
    if (token) {
      navigator.clipboard.writeText(token);
    }
  };

  const formatValue = (value: any) => {
    const parsed = parseFloat(value)
    const valueInKilometers = parsed / 10 ** 6

    if (parseFloat(valueInKilometers.toFixed(3)) === 0) {
      const formated = parsed.toFixed(3).replace(".", ",").replace(/\B(?=(\d{5})+(?!\d))/g, ".");
      return <Typography>{formated} (m<sup>2</sup>)</Typography>;
    }
    else {
      const formated = valueInKilometers.toFixed(3).replace(".", ",").replace(/\B(?=(\d{5})+(?!\d))/g, ".");
      return <Typography>{formated} (km<sup>2</sup>)</Typography>;
    }
  };

  const renderKpisInfo = () => {
    const datasetList = listDataset[optionSelectKPI.split(":")[1]]
    const selectedDataset = datasetList?.reduce(
      (acc: string, dataset: any) => {
        if (`${dataset.id_dataset}:${dataset.id_project}` === optionSelectKPI)
          acc = dataset
        return acc
      }, {})

    const kpisInfo = selectedDataset ? dataResultsMap[selectedDataset.id_dataset] : null

    return (
      <DatasetKpisInfo
        listDataset={listDataset}
        area={area ? formatValue(area) : "--"}
        optionSelectKPI={optionSelectKPI}
        handleChangeSelect={handleChangeSelect}
        selectedDataset={selectedDataset}
        kpisInfo={kpisInfo}
      />
    )
  }

  return (
    <>
      {openPanel && (
        <div className="dash">
          <Box className="panel-box">
            <Tabs
              value={value}
              onChange={handleChangeTab}
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Tab className="dashboard-tab" label={t('dashboardResults.layerTab')} value="0" />
              <Tab className="dashboard-tab" label={t('dashboardResults.resultsTab')} value="1" />
              <Tab className="dashboard-tab" label={t('dashboardResults.sharingTab')} value="2" />
            </Tabs>
          </Box>
          {value === "0" && (
            <div role="tabpanel">
              <Box sx={{ p: 3 }}>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                ></Typography>
                {Object.values(listDataset).map((pro: any) =>
                  pro.map(
                    (
                      dataset: any,
                      index: number
                    ) => {
                      const kpisGroups = dataResultsMap[dataset.id_dataset]?.groups
                      const kpisChartInfo = dataResultsMap[dataset.id_dataset]?.chart
                      return (
                        dataset.state === "Completed" &&
                        <DatasetInfoCard
                          index={index}
                          dataset={dataset}
                          updateDataset={updateDataset}
                          xyzLayerActive={xyzLayerActive}
                          kpisGroups={kpisGroups}
                          legendData={kpisChartInfo?.map((interval: any) => interval[0])}
                          setSnackbarProps={setSnackbarProps}
                          setOpenSnackbar={setOpenSnackbar}
                          handleChangeCheckDataset={handleChangeCheckDataset}
                          handleChangeTransparencyXyz={handleChangeTransparencyXyz}
                          toggleXyzLayer={toggleXyzLayer}
                          handleChangeTransparency={handleChangeTransparency}
                        />
                      );
                    }
                  )
                )}
              </Box>
            </div>
          )}
          {value === "1" && (
            <div role="tabpanel" className="results-container">
              <Box sx={{ p: 3 }}>
                {renderKpisInfo()}
              </Box >
            </div >
          )
          }
          {
            value === "2" && (
              <div role="tabpanel">
                <Box sx={{ p: 3 }}>
                  <TextField
                    label="Token"
                    disabled
                    value={token}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleCopyPassword}>
                          <FileCopyOutlined />
                        </IconButton>
                      ),
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        color: "black",
                        "-webkit-text-fill-color": "black",
                      },
                    }}
                  />
                </Box>
              </div>
            )
          }
        </div >
      )
      }
    </>
  );
}

export default MenuMap;
