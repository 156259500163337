import React, { useState, useEffect } from "react";
import logo_energy from "../../img/boltsharp-6@2x.png";
import logo_energy_hover from "../../img/Bolt_purple.png";
import logo_event from "../../img/eventoutlined-6@2x.png";
import { Box, Typography, Card, CardContent, Tooltip, Pagination, Modal, IconButton, Radio } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useStyles from "../../theme/styles";
import { CircularProgress } from "@mui/material";
import "./style.scss";
import SearchInputCustom from "../ui/searchInput";
import { useTranslation } from "react-i18next";

function LateralMenu(props: any) {
  const {
    openPanelLeft,
    handleClickLeftPanel,
    listProject,
    handleChangeProject,
    numberPages,
    page,
    handleChangePagination,
    openLoader,
    setOpenLoader,
    handleSearch,
    searchValue,
    onChangeSearchValue,
    lateralMenuRef
  } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  const [iconEnergy, setIconEnergy] = useState(logo_event);

  useEffect(() => {
    openPanelLeft ? setIconEnergy(logo_energy_hover) : setIconEnergy(logo_energy);
  }, [openPanelLeft]);

  const handleCloseModal = () => setOpenLoader(false);

  return (
    <>
      <Modal
        open={openLoader}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="containerLoader">
          <CircularProgress
            sx={{
              color: "#7024D1",
            }}
            size={70}
          />
        </div>
      </Modal>
      <div className="vertical_menu_container">
        <div className="container_img_menu container_img_menu_active" onClick={handleClickLeftPanel}>
          <Tooltip title={t('common.projects')}>
            <img
              src={iconEnergy}
              onMouseEnter={() => !openPanelLeft && setIconEnergy(logo_energy_hover)}
              onMouseLeave={() => !openPanelLeft && setIconEnergy(logo_energy)}
              width="24px"
              height="25px"
              style={{ verticalAlign: "middle" }}
            />
          </Tooltip>
        </div>
      </div>
      {openPanelLeft && (
        <div ref={lateralMenuRef} className="panel_container_left">
          <Box sx={{ p: 3 }}>
            <SearchInputCustom
              icon={(
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>

              )}
              handleSearch={handleSearch}
              value={searchValue}
              onChange={onChangeSearchValue}
            />
            {listProject.length > 0 ?
                Object.values(listProject).map(
                  ({ name_project, content, creation_date, display, checked, id_project }: any, index: number) => {
                    return (
                      display && (
                        <Card sx={{ minWidth: 275 }} className={classes.card} key={index}>
                          <CardContent className={classes.card_content}>
                            <Radio
                              className="radio-dashboard"
                              checked={checked}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleChangeProject(e, id_project, index, name_project)
                              }
                            />
                            <div>
                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {name_project}
                              </Typography>
                              <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                {content}
                              </Typography>
                              <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                {creation_date}
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      )
                    );
                  },
                )
              : <Typography sx={{ fontSize: 13 }} gutterBottom>{t('projects.emptyProjectList')}</Typography>
            }
          </Box>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
            <Pagination
              count={numberPages}
              page={page}
              onChange={handleChangePagination}
            />
          </div>
        </div >
      )
      }
    </>
  );
}

export default LateralMenu;
