import axios, { ResponseType } from "axios";
import { User } from "../models/user";
import { Data } from "../models/data"

const SERVER = process.env.REACT_APP_SERVER_API_DOTGIS;

export const ROUTES: any = {
  GET_DATASET: ` ${SERVER}dataset/list_user_datasets`,
  RUN_DATASET: ` ${SERVER}dataset/run_dataset`,
}
export const userLogin = async (user: User) => {

  return await axios.post(
    `${SERVER}middleware/login/`,
    {
      username: user.email,
      password: user.password,
    }
  );
};

export const fetchData = async (data: any, endpoint: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${ROUTES[endpoint]}/${data}`, HEADERS
  );
};

export const fetchKPISData = async (id_project: any, id_dataset: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}dataset/kpis/${id_project}/${id_dataset}`, HEADERS
  );
};

export const getProjectByUser = async (
  page: number,
  projectName: string | null = null,
  orderBy: string | null = null,
  isOrderByDesc: boolean | null = null,
  datasetStatus: string | null = null
) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const params = {
    name_project: projectName,
    order_by: orderBy,
    desc: isOrderByDesc,
    dataset_status: datasetStatus
  }

  return await axios.get(
    `${SERVER}project/list_projects/${page}`, { ...HEADERS, params: params }
  );
};

export const getNumberPages = async (projectName: string | null = null, datasetStatus: string | null = null) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const params = {
    name_project: projectName,
    dataset_status: datasetStatus
  }

  return await axios.get(
    `${SERVER}project/number_pages`, { ...HEADERS, params: params }
  );
}

export const createProject = async (data: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const formData = new FormData();

  formData.append("name_project", data.project);
  formData.append("description", data.description);

  return await axios.post(
    `${SERVER}project/create_project`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  );
};

export const getListAtribute = async () => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}algorithm_attribute/algorithm_attribute`, HEADERS
  );
};

export const getListAsset = async () => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}algorithm_asset/algorithm_asset`, HEADERS
  );
};

export const getListAlgorithm = async () => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}algorithm/algorithm`, HEADERS
  );
};

export const deleteProject = async (data: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.delete(
    `${SERVER}project/delete_project/${data}`, HEADERS
  );
};

export const deleteDataset = async (id_project: any, id_dataset: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.delete(
    `${SERVER}dataset/delete_dataset/${id_project}/${id_dataset}`, HEADERS
  );
};

export const getProjectById = async (data: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}project_algorithm/filter_project_algorithm/${data}`, HEADERS
  );
};

export const postCreateDataset = async (
  data: Data,
  idProject: string,
  nameAsset: string,
  nameAttribute: string,
  basemap_name: string,
  optionalParameters: {
    dateStart: string | undefined,
    dateEnd: string | undefined,
    cloud: string | undefined,
    minArea: string | undefined,
    spatialScale: number | undefined,
    modelType: string | undefined
  }
) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const formData = new FormData();
  formData.append("id_project", idProject);

  formData.append("name_asset", nameAsset);
  formData.append("name_attribute", nameAttribute);

  const { dateStart, dateEnd, cloud, minArea, spatialScale, modelType } = optionalParameters

  const rawInputAlgParameters: any = {
    mask: data,
    resolution: spatialScale ? undefined : 1,
    start_date: dateStart ? dateStart : undefined,
    end_date: dateEnd ? dateEnd : undefined,
    cloud_percentage: cloud ? cloud : undefined,
    spatial_scale: spatialScale ? spatialScale : undefined,
    minimum_segment_size: minArea ? minArea : undefined,
    model_type: modelType ? modelType : undefined
  }

  const inputAlgParameters = Object.entries(rawInputAlgParameters).reduce((acc: any, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})

  formData.append("input_alg_parameters", JSON.stringify(inputAlgParameters))

  if (basemap_name) {
    formData.append("basemap_name", basemap_name);
  }

  return await axios.post(
    `${SERVER}dataset/create_dataset`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  );
};


export const runDataset = async (id_project: any, datasets: any[]) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const formattedDatasets = []

  for (const dataset of datasets) {
    const formattedDataset: any = {
      id_project: id_project,
      id_dataset: dataset.id_dataset,
      file_name: `${id_project}_${dataset.id_dataset}.tiff`
    }
    if (dataset.basemap_name) {
      formattedDataset.basemap_name = dataset.basemap_name
    }
    if (dataset.basemap_name === 'DTM') {
      formattedDataset.zoom = "14";
      formattedDataset.file_name = `${id_project}_${dataset.id_dataset}`;
    }
    if (dataset.spatial_scale) {
      formattedDataset.zoom = "15";

      formattedDataset.area_of_interest = `${JSON.stringify(dataset.area)}`;
    } else if (dataset.area) {
      formattedDataset.zoom = "19";

      formattedDataset.area_of_interest = `${JSON.stringify(dataset.area)}`;
    }
    formattedDatasets.push(formattedDataset)
  }

  const formData = new FormData();
  formData.append("id_project", id_project);

  formData.append("datasets", JSON.stringify(formattedDatasets));

  return await axios.post(
    `${SERVER}dataset/run_dataset`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  );
};


export const downloadDataset = async (id_project: any, id_dataset: any, typeExtension: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const responseType: ResponseType = 'blob'

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: responseType
  };

  return await axios.get(
    `${SERVER}dataset/download_dataset/${id_project}/${id_dataset}/${typeExtension}`, HEADERS
  );
}

export const postUpladFilesToConvert = async (data: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const formData = new FormData();
  formData.append("file", data);

  return await axios.post(
    `${SERVER}files/upload`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      contentType: "multipart/form-data",
    }
  }
  );
};


export const getXyzFolder = async (id_project: any, id_dataset: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}tile_service/get_tiles_dir/${id_project}/${id_dataset}`, HEADERS
  );
};


export const downloadAoi = async (data: string, fileFormat: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const responseType: ResponseType = 'blob'

  const HEADERS = {
    params: { file_format: fileFormat },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: responseType
  };

  return await axios.get(
    `${SERVER}dataset/download_aoi/${data}`, HEADERS
  );
};


export const existsProject = async (projectName: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const params = projectName === null ? {} : { name_project: projectName }

  return await axios.get(
    `${SERVER}project/exist_project`, { ...HEADERS, params: params }
  );

}

export const getAoiBuffer = async (aoi: any, bufferSize: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();

  formData.append("area_of_interest", JSON.stringify(aoi));
  formData.append("size", bufferSize);

  return await axios.post(
    `${SERVER}aoi/create_buffer`,
    formData,
    HEADERS
  );

}


export const checkAoi = async (aoi: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();

  formData.append("area_of_interest", JSON.stringify(aoi));

  return await axios.post(
    `${SERVER}aoi/check_aoi`,
    formData,
    HEADERS
  );
}

export const editDatasetName = async (idProject: number, idDataset: number, newDatasetName: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();

  formData.append("dataset_name", newDatasetName);

  return await axios.patch(
    `${SERVER}dataset/edit_dataset/${idProject}/${idDataset}`,
    formData,
    HEADERS
  );
}

export const editProject = async (idProject: number, projectFields: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();

  Object.entries(projectFields).forEach((entry:any)=>{
    if (entry[1])
      formData.append(entry[0], entry[1])
  })

  return await axios.patch(
    `${SERVER}project/edit_project/${idProject}`,
    formData,
    HEADERS
  );
}

export const editComment = async (idProject:number, projectFields: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();

  Object.entries(projectFields).forEach((entry:any)=>{
    if (entry[1])
      formData.append(entry[0], entry[1])
  })

  return await axios.patch(
    `${SERVER}project/comments/${idProject}`,
    formData,
    HEADERS
  );
}

export const addComment = async (idProject:number, comment: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();
  formData.append("comment", comment)

  return await axios.post(
    `${SERVER}project/comments/${idProject}`,
    formData,
    HEADERS
  );
}

export const getUserData = async () => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}user/data`,
    HEADERS
  );
}

export const editUserData = async (newUserInfo: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();

  Object.entries(newUserInfo).forEach((entry:any)=>{
    if (entry[1] !== null)
      formData.append(entry[0], entry[1])
  })

  return await axios.patch(
    `${SERVER}user/data`,
    formData,
    HEADERS
  );
}