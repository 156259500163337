// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonStyleColor {
  background: #7024d1 !important;
  color: #ffffff !important;
}

.buttonStyleColorDisable {
  background: rgba(112, 36, 209, 0.2) !important;
}

.buttonStyleColoOutline {
  background: #ffffff !important;
  border: 1px solid #7024d1 !important;
  color: #7024d1 !important;
}

.buttonImageryst {
  text-transform: none !important;
  font-size: 12px !important;
}
.buttonImageryst:disabled {
  color: #A8A8A8 !important;
  border: 1px solid #A8A8A8 !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}

.backButton {
  color: #7024D1 !important;
  background: none !important;
  border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/button/style.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,yBAAA;AACJ;;AAEA;EACI,8CAAA;AACJ;;AAEA;EACI,8BAAA;EACA,oCAAA;EACA,yBAAA;AACJ;;AAEA;EACI,+BAAA;EACA,0BAAA;AACJ;AAAI;EACI,yBAAA;EACA,oCAAA;EACA,8BAAA;EACA,YAAA;AAER;;AAEA;EACI,yBAAA;EACA,2BAAA;EACA,uBAAA;AACJ","sourcesContent":[".buttonStyleColor {\n    background: #7024d1 !important;\n    color: #ffffff !important;\n}\n\n.buttonStyleColorDisable {\n    background: rgba(112, 36, 209, 0.2) !important;\n}\n\n.buttonStyleColoOutline {\n    background: #ffffff !important;\n    border: 1px solid #7024d1 !important;\n    color: #7024d1 !important;\n}\n\n.buttonImageryst{\n    text-transform: none !important;\n    font-size: 12px !important;\n    &:disabled {\n        color: #A8A8A8 !important;\n        border: 1px solid #A8A8A8 !important;\n        cursor: not-allowed !important;\n        opacity: 0.5,\n    }\n}\n\n.backButton {\n    color: #7024D1 !important;\n    background: none !important;\n    border: none !important;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
