import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  tabs: {
    color: "#fff !important",
    "& .Mui-selected": {
      color: "#7024D1 !important",
    },
    "& .MuiTabs-indicator": {
      //background: theme.palette.primary.main,
    },
  },
  menu: {
    borderColor: "rgba(0, 0, 0, 0) !important",
  },
  iconClose: {
    cursor: "pointer",
    float: "right",
  },
  divBtn: {
    display: "flex",
    marginRight: "22px",
  },
  containerSearch: {
    marginTop: "22px",
    width: "230px",
    marginRight: "10px",
  },
  containerMeasurement: {
    marginTop: "22px",
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "5px",
    marginRight: "10px",
    backgroundColor: "white",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px"
  },
  btnNoOpen: {
    borderRadius: "2px !important",
  },
  btnOpen: {
    borderRadius: "2px 0px 0px 2px !important",
  },
  btn: {
    marginright: "22px",
    margintop: "22px",
    maxHeight: "40px",
    "&:hover": {
      backgroundColor: "#ffffff !important",
    },
    "& span": {
      margin: "0px !important",
    },
  },
  btn_search: {
    color: "#000000 !important",
    "&:hover": {
      color: "#7024D1 !important",
    },
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 14,
    height: 14,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(237, 7, 53,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#7024D1",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 14,
      height: 14,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#7024D1",
    },
  },
  slider: {
    width: "100%",
  },
  radioGroup: {
    marginBottom: "1rem",
  },
  selectForm: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&&:after": {
      borderBottom: "none",
    },
  },
  menuItem: {
    maxWidth: "10rem",
    ".MuiListItem-root.Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  divContent: {
    padding: "8px 36px 16px",
  },
  legend: {
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: "7.69231%",
    display: "table-cell !important",
    height: "10px",
    widht: "20px"
  },
  containerTable: {
    boxShadow: "none !important",
  },
  headerTableC: {
    borderBottom: "none !important",
    padding: "0px !important",
    color: "#6C7175 !important",
    paddingBottom: "10px !important"
  },
  tableC: {
    borderBottom: "none !important",
    padding: "0px !important",
  },
  btnB: {
    borderRadius: "20px !important",
    margin: "12px !important",
    maxHeight: "40px",
    textTransform: 'none'
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputWhite: {
    backgroundColor: "#fff !important",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row-reverse",
    margin: "70px 122px 0px",
    "& div": {
      marginTop: "16px",
    },
    "& .container_button": {
      width: "50%",
      position: "absolute",
      right: "0px",
      bottom: "30px",
    },
  },
  headerContent: {
    margin: "50px 122px",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "50px 20px",
  },
  pageTitle: {},
  pageContent: {},
  title: {
    margin: "50px 122px !important",
  },
  iconFavo: {
    position: "absolute",
    color: "#f9fb009e !important",
  },
  iconNoFavo: {
    position: "absolute",
  },
  marginRight: {
    marginRight: "10px",
  },
  cursorBtn: {
    cursor: "pointer",
  },
  imgRight: {
    float: "right",
  },
  step: {
    "& .MuiStepLabel-iconContainer .Mui-completed": {
      color: "#7024d1",
    },
    "& .MuiStepLabel-iconContainer .Mui-active": {
      color: "#7024d1",
    },
  },
  activeStep: {
    opacity: 1,
  },
  disabledStep: {
    opacity: 0.5,
    color: "#A8A8A8 !important",
    "& *": {
      color: "#A8A8A8 !important",
      borderColor: "#A8A8A8 !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#A8A8A8 !important",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#A8A8A8 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#A8A8A8 !important",
    },
  },
  hideBtn: {
    display: "none",
  },
  dataHide: {
    display: "none",
  },
  sandBoxDiv: {
    height: "440px",
    marginRight: "20px",
    marginBottom: "20px",
    overflowY: "auto",
    width: "40vw",
  },
  stepperDiv: {
    width: "40vw",
  },
  card: {
    marginTop: "24px",
    marginBottom: "0px",
  },
  card_content: {
    display: "flex",
    alignItems: "center",
  },
  card_icon: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    cursor: "pointer",
    "& svg": {
      width: "1.5em",
    },
  },
  card_contentkpi: {
    flexDirection: "column",
    display: "flex",
    color: "#000000",
  },
  card_kpi: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "& .MuiTypography-root": {
      fontWeight: "bold",
      fontSize: "1.6rem",
      textShadow: "0.5px 0.5px 0 #000, -0.5px -0.5px 0 #000",
    },
  },
  card_kpi_items: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& .MuiTypography-root": {
      fontWeight: "bold",
      fontSize: "1.6rem",
      textShadow: "0.5px 0.5px 0 #000, -0.5px -0.5px 0 #000",
    },
  },
  select: {
    margin: "12px 0px !important",
    width: "100%",
  },
  icon_grey: {
    color: "#888888 !important",
  },
  imgIcon: {
    width: "20px",
  },
  divCardContent: {
    display: "flex",
  },
  card50: {
    minWidth: "45% !important",
    borderLeft: "4px solid #7024d1",
  },
  input: {
    marginBottom: "24px !important",
  },
  tabpanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  colorCircular: {
    color: "#7024D1"
  },
  colorTextField:{
    // input label when focused
    "& label": {
      paddingLeft: "8px",
    },
    "& label.Mui-focused": {
      color: "#7024D1",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#7024D1",
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#7024D1",
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#7024D1",
      },
    },
  },
  colorTextFieldAlways:{
    "& .MuiInputBase-root:before": {
      borderBottom: "1px solid #7024D1 !important",
    },
    "& .MuiFilledInput-root:before": {
      borderBottom: "1px solid #7024D1 !important",
    },
    "& label": {
      color: "#7024D1",
    },
    "& MuiFilledInput-root": {
      backgroundColor: "#7024D1",
    },
    // input label when focused
    "& label.Mui-focused": {
      color: "#7024D1",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#7024D1",
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#7024D1",
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#7024D1",
      },
    },
  },
  colorPassword:{
    "& label": {
      color: "#7024D1",
    },
    "& .MuiFilledInput-input:before": {
      borderBottom: "1px solid #7024D1 !important",
    },
    "& .MuiFilledInput-root:after": {
      borderColor: "#7024D1 !important",
    },
  },
  selectColor: {
    '&:before': {
      borderBottom: "2px solid #7024D1 !important",
    },
    '&:after': {
      borderBottom: "2px solid #7024D1 !important",
    },
    '&:not(.Mui-disabled):hover::before': {
      borderBottom: "2px solid #7024D1 !important",
    },
  },
  labelColor:{
    ".MuiInputLabel-root.Mui-focused": {
      color: "#7024D1 !important",
    },
  }
}));

export default useStyles;
