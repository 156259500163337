import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Slider, TextField, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InvertColorsOutlinedIcon from "@mui/icons-material/InvertColorsOutlined";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import CheckboxCustom from "../../ui/checkbox";
import DownloadDataset from "../downloadDataset";
import StyleCard from "../../styleCard";
import useStyles from "../../../theme/styles";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { editDatasetName } from "../../../utils/requests";
import { EditTextField } from "../../ui/textField";


function DatasetInfoCard(props: any) {
    const {
        index,
        dataset,
        updateDataset,
        xyzLayerActive,
        kpisGroups,
        legendData,
        setSnackbarProps,
        setOpenSnackbar,
        handleChangeCheckDataset,
        handleChangeTransparencyXyz,
        toggleXyzLayer,
        handleChangeTransparency
    } = props

    const classes = useStyles()

    const { t } = useTranslation()

    const [editMode, setEditMode] = useState(false)
    const [newDatasetName, setNewDatasetName] = useState(dataset.dataset_name)

    const toggleEditDatasetName = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setEditMode(!editMode)
    }

    const saveDatasetName = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        const editedDataset = await editDatasetName(dataset.id_project, dataset.id_dataset, newDatasetName).then(r => {
            if (r.status == 200) {
                return r.data.dataset
            }
            return null
        }).catch(err => {
            setSnackbarProps({ message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"), severity: 'error' })
            setOpenSnackbar(true)
            return null
        })
        if (editedDataset) {
            setSnackbarProps({ message: t("common.genericSuccessMesage"), severity: 'success' })
            setOpenSnackbar(true)
            updateDataset(editedDataset.id_project, editedDataset)
            setEditMode(!editMode)
        }
    }

    return (
        <>
            <Accordion
                className="accordionItem"
                key={`${index}_accordion`}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={`accordionTitle ${classes.card_content}`}
                    sx={{
                        pointerEvents: "none",
                    }}
                    key={`${index}_accordion_summary`}
                >
                    <Grid
                        container
                        rowSpacing={1}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <Grid item xs={2}>
                            <CheckboxCustom
                                handleChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    handleChangeCheckDataset(
                                        e,
                                        dataset.id_project,
                                        dataset.id_dataset,
                                        dataset.name_attribute,
                                        dataset.transparency,
                                        dataset.subimage
                                    )
                                }
                                checked={dataset.checked}
                                icons={true}
                            />
                        </Grid>
                        <EditTextField
                            editMode={editMode}
                            originalName={dataset.dataset_name}
                            newText={newDatasetName}
                            onChangeText={
                                (event: React.ChangeEvent<HTMLInputElement>) => {
                                    setNewDatasetName(event.target.value)
                                }
                            }
                            onEditClick={toggleEditDatasetName}
                            onSave={saveDatasetName}
                            onCancel={toggleEditDatasetName}
                        />
                        <Grid item
                            xs={12}
                            container
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div className={classes.card_icon}>
                                <span onClick={(e: any) => { e.stopPropagation() }}>
                                    <Tooltip
                                        title={`${dataset.project_name} - ${dataset.last_execution_date}`}
                                        placement="top-end"
                                    >
                                        <InfoOutlinedIcon
                                            titleAccess={t('common.infoLayer')}
                                            sx={{
                                                pointerEvents: "auto",
                                            }}
                                        />
                                    </Tooltip>
                                </span>
                                <InvertColorsOutlinedIcon
                                    titleAccess={t('common.symbologySettings')}
                                    sx={{
                                        pointerEvents: "auto",
                                    }}
                                />

                                {dataset.url_image ? (
                                    <MapOutlinedIcon
                                        titleAccess={t('common.toggleXYZLayer')}
                                        sx={{
                                            pointerEvents: dataset.checked ? "auto" : "none",
                                        }}
                                        className={!dataset.checked ? "xyz-disabled" : xyzLayerActive[dataset.id_dataset] ? "xyz-active" : "xyz-inactive"}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            if (dataset.checked) {
                                                handleChangeTransparencyXyz(
                                                    e,
                                                    dataset.id_project,
                                                    dataset.id_dataset,
                                                    dataset.url_image
                                                );
                                                toggleXyzLayer(dataset.id_dataset);
                                            }
                                        }}
                                    />
                                ) : (
                                    <MapOutlinedIcon
                                        titleAccess={t('common.toggleXYZLayer')}
                                        sx={{
                                            pointerEvents: "auto",
                                            color: "lightgrey",
                                        }}
                                    />
                                )}
                                <DownloadDataset
                                    id_dataset={dataset.id_dataset}
                                    id_project={dataset.id_project}
                                    project_name={dataset.project_name}
                                    name_asset={dataset.name_asset}
                                    name_attribute={dataset.name_attribute}
                                    index={index}
                                    setSnackbarProps={setSnackbarProps}
                                    setOpenSnackbar={setOpenSnackbar} />
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            sx={{
                                pointerEvents: dataset.checked ? "auto" : "none",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <StyleCard
                                type={dataset.name_attribute}
                                kpisGroups={kpisGroups}
                                legendData={legendData}
                                checked={dataset.checked}
                            />
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails key={`${index}_accordion_details`}>
                    <Box sx={{
                        marginLeft: 5,
                    }}>
                        <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.opacity')}</p>
                        <Slider
                            style={{ width: "80%" }}
                            size="small"
                            valueLabelDisplay="auto"
                            value={dataset.transparency}
                            onChange={(
                                e: any
                            ) =>
                                handleChangeTransparency(
                                    e,
                                    dataset.id_project,
                                    dataset.id_dataset,
                                    dataset.name_attribute,
                                    dataset.subimage
                                )}
                            marks={[
                                {
                                    value: 0,
                                    label: "0%",
                                },
                                {
                                    value: 100,
                                    label: "100%",
                                },
                            ]}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default DatasetInfoCard;