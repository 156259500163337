import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, Menu, MenuItem, Button, Tooltip, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonCustom from "../../components/ui/button";
import "./style.scss";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../languageSelector";
import { Imageryst7 } from "../../icons/logo";

function Header(props: any) {
  const { valueTab } = props;
  const history = useHistory();

  const { t } = useTranslation()

  const [isProjectView, setIsProjectView] = useState(false)

  const [value, setValue] = useState(valueTab);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    history.push(`/${newValue}`);
  };

  const handleClickBack = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id_user");
    localStorage.removeItem("token_access_imageryst");

    history.push("/");
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNew = () => {
    history.push({ pathname: "/newProject" });
  };

  const handleClickUserProfile = () => {
    history.push({ pathname: "/profile" });
  };


  useEffect(() => {
    setIsProjectView(history.location.pathname === "/projects")
  }, [history])


  return (
    <div className="menu_container">
      <div className="logo">
        <Grid container spacing={5} justifyItems={'center'} alignItems={'center'}>
          <Grid item>
            <Imageryst7 sx={{ width: "55.13px", height: "51.75px" }} />
          </Grid>
          <Grid item>
            <LanguageSelector />
          </Grid>
        </Grid>
      </div>
      <div className="menu">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="tabs">
          <Tabs
            onChange={handleChange}
            className="menu"
            value={value}
            textColor="secondary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#7024d1",
              },
            }}
          >
            {/*<Tab value="home" label="Home" />*/}
            {/*<Tab value="imageCatalog" label="Image Catalog" />*/}
            <Tab
              value="projects"
              label={t('common.projects')}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setValue("projects");
                history.push(`/projects`);
              }} />
            <Tab
              value="dashboardResults"
              label={t('common.dashboardResults')}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setValue("dashboardResults");
                history.push(`/dashboardResults`);
              }} />
          </Tabs>
        </Box>
      </div>
      <div className="user">
        <div className="container_newP">
          {isProjectView ? <ButtonCustom
            label={t('newProject.newProject')}
            className="buttonStyleColor"
            variant="contained"
            handleClick={handleClickNew}
            tooltip="Create new project"
          /> : null}
        </div>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Imageryst7 sx={{ width: "inherit", height: "inherit" }} />
          <ExpandMoreIcon style={{ color: '#7024D1' }} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          disableScrollLock={true}
        >
          <Tooltip title={t('common.logout')}>
            <MenuItem onClick={handleClickUserProfile}>{t('common.profile')}</MenuItem>
          </Tooltip>
          <Tooltip title={t('common.logout')}>
            <MenuItem onClick={handleClickBack}>{t('common.logout')}</MenuItem>
          </Tooltip>
        </Menu>
      </div>
    </div>
  );
}

export default Header;
